import React, { FC } from 'react'
import lawsStore from 'src/components/laws/laws-store'
import ShowChangesIcon from 'src/components/icons/ShowChanges.svg'
import ShowChangesActiveIcon from 'src/components/icons/ShowChangesActive.svg'
import { isSafari } from 'src/helpers/layout'

interface IShowDeletedTextMenuProps {
  visible: boolean
}

const ShowDeletedTextMenu: FC<IShowDeletedTextMenuProps> = ({ visible }) => {
  if (!visible) {
    return <></>
  }
  const setIsDeletedTextShown = lawsStore(
    (state) => state.setIsDeletedTextShown
  )

  const isDeletedTextShown = lawsStore((state) => state.isDeletedTextShown)

  const isSectionOnScreen = (element: HTMLElement) => {
    const windowHeight = window.innerHeight
    const bounding = element.getBoundingClientRect()

    const top = bounding.top - windowHeight
    const { bottom } = bounding

    return top < 0 && bottom > 0
  }

  const fixScrollShiftOnSafari = () => {
    if (!isSafari()) {
      return
    }

    const paragraphs = Array.from(document.querySelectorAll('p, li'))
    const paragraphOnScreen = paragraphs.find((paragraph) =>
      isSectionOnScreen(paragraph)
    )
    const distanceFromTopBeforeChange = paragraphOnScreen.offsetTop

    // setTimeout to wait for re-render and then re-calculate offsetTop
    setTimeout(() => {
      const currentDistanceFromTop = paragraphOnScreen.offsetTop
      const scrollDiff = distanceFromTopBeforeChange - currentDistanceFromTop

      window.scrollBy(0, -scrollDiff)
    })
  }

  const handleClick = () => {
    setIsDeletedTextShown(!isDeletedTextShown)
    fixScrollShiftOnSafari()
  }

  return (
    <div className="fixed top-28 right-4 sm:top-42 md:top-54 lg:top-42 lg:right-10">
      <button type="button" onClick={() => handleClick()}>
        <div className="relative w-8 lg:w-12 " title="display changes">
          <img
            src={ShowChangesIcon}
            alt="Show changes"
            className={`w-full transform transition duration-500 ${
              isDeletedTextShown ? 'rotate-180 opacity-0' : 'opacity-100'
            }`}
          />
          <img
            src={ShowChangesActiveIcon}
            alt="Hide changes"
            className={`w-full absolute top-0 left-0 transform transition duration-500 ${
              isDeletedTextShown ? 'opacity-100 rotate-180' : 'opacity-0'
            }`}
          />
        </div>
      </button>
    </div>
  )
}

export default ShowDeletedTextMenu
