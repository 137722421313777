import React, { FC } from 'react'
import CloseIcon from 'src/images/icons/close.inline.svg'
import { LazyMotion, m, domAnimation } from 'framer-motion'

interface ILawChangeExplanationProps {
  explanation: string
  coordinates: { x: number; y: number }
  isShown: boolean
  setIsShown: (isShown: boolean) => void
}

const OFFSET_TOP_IN_PX = 45

export const animationVariants = {
  hidden: {
    opacity: 0,
    transitionEnd: {
      display: 'none',
    },
  },
  show: {
    display: 'block',
    opacity: 1,
  },
}

const LawChangeExplanation: FC<ILawChangeExplanationProps> = ({
  explanation,
  coordinates,
  setIsShown,
  isShown,
}) => {
  const handleClick = () => {
    setIsShown(false)
  }
  return (
    <LazyMotion features={domAnimation}>
      <m.div
        variants={animationVariants}
        initial={isShown ? 'show' : 'hidden'}
        animate={isShown ? 'show' : 'hidden'}
        transition={{ duration: 0.25 }}
        className="p-inherit absolute w-full left-0 right-0"
        style={{ top: coordinates.y + OFFSET_TOP_IN_PX, paddingTop: 0 }}
      >
        <div className="container">
          <div className="p-8 bg-deepDarkBlue text-white text-left l-0 r-0 relative">
            <button
              className="absolute right-3 top-3 pointer"
              type="button"
              onClick={handleClick}
            >
              <CloseIcon
                aria-label="Close Icon"
                className="w-5 text-white stroke-current"
              />
            </button>
            {explanation}
          </div>
        </div>
      </m.div>
    </LazyMotion>
  )
}

export default LawChangeExplanation
