import React, { useEffect, useState } from 'react'
import { useIsLargeScreen } from 'src/helpers/layout'
import styled from 'styled-components'
import tw from 'twin.macro'

const StyledStickyTitle = styled.div`
  &.sticked {
    ${tw`fixed bg-brightYellow w-full top-0 left-0 z-10`}
    span {
      ${tw`whitespace-normal`}
    }
    br {
      ${tw`hidden`}
    }
  }
`

const StickyWrapper = () => (WrappedComponent) => ({
  children,
  stickedMobileContent,
}) => {
  const [isSticked, setIsSticked] = useState(false)
  const isLarge = useIsLargeScreen()

  useEffect(() => {
    const checkIfShouldBeSticked = () => {
      const title = document.getElementById('sticky-title')
      const titleOffset = window.pageYOffset + title.getBoundingClientRect().top
      const titleHeight = title.offsetHeight
      const scrollTop = window.scrollY

      setIsSticked(scrollTop > titleOffset + titleHeight * 0.5)
    }

    window.addEventListener('scroll', checkIfShouldBeSticked)
    window.addEventListener('resize', checkIfShouldBeSticked)

    return () => {
      window.removeEventListener('scroll', checkIfShouldBeSticked)
      window.removeEventListener('resize', checkIfShouldBeSticked)
    }
  })

  const getTitleContent = () => {
    if (!isLarge && isSticked && stickedMobileContent) {
      return stickedMobileContent
    }

    return children
  }

  return (
    <>
      <div id="sticky-title">
        <StyledStickyTitle className={`relative ${isSticked ? 'sticked' : ''}`}>
          <WrappedComponent isSticked={isSticked}>
            {getTitleContent()}
          </WrappedComponent>
        </StyledStickyTitle>

        <div className={`invisible ${!isSticked ? 'hidden' : ''}`}>
          <WrappedComponent>{children}</WrappedComponent>
          {/* Placeholder, to keep the same height */}
        </div>
      </div>
    </>
  )
}

export default StickyWrapper
