import React, { FC } from 'react'
import { theme } from 'src/../tailwind.config'

interface IResponsiveImageProps {
  desktopSrc: string
  fallbackDesktopSrc: string
  mobileSrc: string
  fallbackMobileSrc: string
  alt: string
}

const ResponsiveImage: FC<IResponsiveImageProps> = ({
  desktopSrc,
  mobileSrc,
  alt,
}) => (
  <picture>
    <source
      media={`(max-width: ${theme.extend.screens.md})`}
      srcSet={`
        ${mobileSrc || desktopSrc}?auto=format&q=90&w=768&fit=max 1x,
        ${mobileSrc || desktopSrc}?auto=format&q=90&w=1536&fit=max 2x,
        ${mobileSrc || desktopSrc}?auto=format&q=90&w=3072&fit=max 3x
      `}
    />
    <source
      media="(max-width: 1920px)"
      srcSet={`
        ${desktopSrc}?auto=format&q=90&w=1920&fit=max 1x,
        ${desktopSrc}?auto=format&q=90&w=4096&fit=max 2x,
      `}
    />
    <img src={`${desktopSrc}?auto=format&q=90`} alt={alt} />
  </picture>
)

export default ResponsiveImage
