/* eslint-disable class-methods-use-this,global-require */
import React, { FC } from 'react'
import { Markup, Node } from 'interweave'
import { isBrowser } from 'src/helpers/layout'
import ResponsiveImage from 'src/components/image/ResponsiveImage'
import InteractiveFieldOfPlay from 'src/components/laws/interactive-picture/field-of-play'
import ThinHeader from 'src/components/layout/ThinHeader'

interface IInterweaveContentProps {
  content: string
}

const transform = (node: HTMLElement, children: Node[]): React.ReactNode => {
  const lowercaseTagName = node.tagName.toLowerCase()
  switch (lowercaseTagName) {
    case 'thefieldinteractivepicture':
      return <InteractiveFieldOfPlay />
    case 'responsiveimage':
      return (
        <ResponsiveImage
          mobileSrc={node.getAttribute('mobilesrc')}
          desktopSrc={node.getAttribute('desktopsrc')}
          fallbackMobileSrc={node.getAttribute('fallbackmobilesrc')}
          fallbackDesktopSrc={node.getAttribute('fallbackdesktopsrc')}
          alt={node.getAttribute('alt')}
        />
      )
    case 'thinheader':
      return (
        <ThinHeader className={node.getAttribute('classname')}>
          {children}
        </ThinHeader>
      )
    default:
      return undefined
  }
}

const InterweaveContent: FC<IInterweaveContentProps> = ({ content }) => {
  if (!isBrowser) {
    const { polyfill } = require('interweave-ssr')
    polyfill()
  }

  return (
    <>
      <Markup
        content={content}
        transform={transform}
        allowElements
        allowAttributes
      />
    </>
  )
}

export default InterweaveContent
