import React, { FC, useEffect, useState } from 'react'
import Subtitle from 'src/components/typography/Subtitle'
import { StyledListWrapper } from 'src/components/typography/List'
import Article from 'src/components/typography/Article'
import styled from 'styled-components'
import tw from 'twin.macro'
import { IImage, ISection } from 'src/components/laws'
import HeightAnimation from 'src/components/layout/HeightAnimation'
import { useIsLargeScreen } from 'src/helpers/layout'
import { CmsHtmlContent } from 'src/components/typography/CmsHtmlContentFormats'
import 'src/components/laws/law-accordion-styles.css'
import config from 'src/../tailwind.config'
import P from 'src/components/typography/Paragraph'
import lawsStore from 'src/components/laws/laws-store'
import InterweaveContent from 'src/components/laws/InterweaveContent'
import ParallaxImage from '../parallax-image'
import AudioPlayer from '../audio/AudioPlayer'

interface ILawSectionProps {
  section: ISection
  upperIndex: number
  isLast: boolean
  isBasicLaw: boolean
}

export const DynamicHtmlStyles = styled.div`
  ul + p {
    ${tw`mt-5`}
  }
  p:last-child {
    ${tw`mb-0`}
  }
  explanation {
    ${tw`cursor-pointer`}
    &:hover {
      ${tw`bg-brightYellow`}
      addedexplanation, deletedexplanation {
        ${tw`bg-brightYellow`}
      }
    }
  }
  addedexplanation,
  added {
    ${tw`relative underline`}
    text-decoration-thickness: 2px;
  }
  addedexplanation {
    text-decoration-color: ${config.theme.extend.colors.darkenYellow};
  }
  added {
    text-decoration-color: ${config.theme.extend.colors.navyBlueGray};
  }
  deletedexplanation,
  deleted {
    display: none;
    ${tw`line-through`}
  }
  u {
    text-decoration-color: ${config.theme.extend.colors.navyBlueGray};
  }
  li,
  ul {
    &.deleted-content {
      display: none;
    }
  }
  .show-deleted-text {
    deletedexplanation,
    deleted {
      display: inline;
    }
    li.deleted-content {
      display: list-item;
    }
    ul.deleted-content {
      display: block;
    }
  }
`

const LawSection: FC<ILawSectionProps> = ({
  section,
  upperIndex,
  isLast,
  isBasicLaw,
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const isLarge = useIsLargeScreen()

  const transitionBlock = section.transitionBlock && section.transitionBlock[0]

  const isDeletedTextShown = lawsStore((state) => state.isDeletedTextShown)

  const filterGalleryImages = (images: IImage[]) =>
    images.filter((image) => image.displayedInGallery)

  const subtitleClasses = `
    transition-all ease-in-out duration-500
    laws-accordion-title
    whitespace-pre-line
    ${isOpen || !isBasicLaw ? 'laws-accordion-title--open' : ''}
    ${isLast ? 'laws-accordion-title--last' : ''}
  `

  useEffect(() => {
    if (!window?.location?.hash) {
      return
    }
    const hash = window.location.hash.substr(1)
    if (hash === section.slug) {
      setIsOpen(true)
    }
  }, [])

  const displayUpperIndex = isBasicLaw

  const getDisplayIndex = (): JSX.Element => {
    if (!section.sectionIndex) {
      return <></>
    }

    if (!displayUpperIndex) {
      return <>{section.sectionIndex}. </>
    }

    return (
      <>
        {upperIndex}.{section.sectionIndex}{' '}
      </>
    )
  }

  const mobileTitle = () =>
    section.mobileTitleOverride ?? section.lawSectionCategoryName
  const desktopTitle = () =>
    section.desktopTitleOverride ?? section.lawSectionCategoryName
  const subtitleClick = () => {
    if (!isBasicLaw) {
      return
    }
    setIsOpen(!isOpen)
  }

  const displayIndex = getDisplayIndex()

  const TitleWithButton = () => {
    if (isLarge) {
      return (
        <Subtitle className={subtitleClasses}>
          <>
            {displayIndex}
            {desktopTitle()}
          </>
        </Subtitle>
      )
    }

    return (
      <Subtitle className={subtitleClasses}>
        <button
          onClick={subtitleClick}
          type="button"
          className={`${!isBasicLaw ? tw`cursor-default` : ''}`}
        >
          <span className="flex items-start py-0.5">
            {(section.sectionIndex || isBasicLaw) && (
              <>
                <span className="mr-3 leading-none w-8 flex-shrink-0">
                  {getDisplayIndex()}
                </span>
                <span> </span>
              </>
            )}
            <span className="leading-none">{mobileTitle()}</span>
          </span>
        </button>
      </Subtitle>
    )
  }

  const contentBlock = (
    <>
      <DynamicHtmlStyles>
        <CmsHtmlContent>
          <StyledListWrapper
            className={`text-left ${
              isDeletedTextShown ? 'show-deleted-text' : ''
            }`}
          >
            <InterweaveContent content={section.content} />
            <AudioPlayer content={section.audio} className="w-64 mt-5 mb-1.5" />
          </StyledListWrapper>
        </CmsHtmlContent>
      </DynamicHtmlStyles>
      <div>
        {filterGalleryImages(section.images).map((image) => (
          <div key={image.url} className="mb-20">
            <img src={image.url} alt={image.caption} />
            <P>{image.caption}</P>
          </div>
        ))}
      </div>
    </>
  )

  const lawSection = () => (
    <div className="bg-white overflow-hidden">
      <Article
        key={section.slug}
        id={section.slug}
        noMarginMobile={!isOpen && isBasicLaw}
        className={`
          container
          border-b border-t border-transparent
          laws-accordion-section
          ${isLast ? 'laws-accordion-section--last' : ''}
          ${isOpen || !isBasicLaw ? 'laws-accordion-section--open' : ''}
        `}
      >
        {TitleWithButton()}
        {isBasicLaw ? (
          <HeightAnimation
            isOpen={isOpen || isLarge}
            className="laws-accordion-content"
          >
            {contentBlock}
          </HeightAnimation>
        ) : (
          <div className="laws-accordion-content">{contentBlock}</div>
        )}
      </Article>
    </div>
  )

  const parallaxImage = () => (
    <ParallaxImage
      desktopImage={transitionBlock.desktopImage}
      mobileImage={transitionBlock.mobileImage}
      id={transitionBlock.id}
      className={`${isOpen || !isBasicLaw ? 'show' : 'hidden'}`}
    />
  )

  return (
    <>
      {lawSection()}
      {transitionBlock ? parallaxImage() : <></>}
    </>
  )
}

export default LawSection
