// i18next-extract-mark-ns-start laws

import React, { FC, useEffect, useRef, useState } from 'react'
import Layout from 'src/components/layout'
import ContentPageContainer from 'src/components/layout/ContentPageContainer'
import { graphql } from 'gatsby'
import { IChapter } from 'src/components/laws'
import Helmet from 'react-helmet'
import {
  replaceAnchorInUrlBySections,
  scrollToSectionId,
} from 'src/helpers/layout'
import sideMenuStore from 'src/components/side-menu/side-menu-store'
import LawChangeExplanation from 'src/components/laws/LawChangeExplanation'
import { toggleExplanationOnHover } from 'src/helpers/laws'
import LawSection from 'src/components/laws/LawSection'
import StickyTitle from 'src/components/laws/StickyTitle'
import ShowDeletedTextToggler from 'src/components/laws/ShowDeletedTextToggler'
import Section from 'src/components/typography/Section'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import PageTitle from 'src/components/typography/PageTitle'
import QuestionAndAnswer, {
  IQuestionAndAnswerProps,
} from 'src/components/cms-page/blocks/QuestionAndAnswer'
import Highlight from 'src/components/cms-page/blocks/Highlight'
import apiKeys from 'src/components/cms-page/api-keys'

interface IQuestionAndAnswerData extends IQuestionAndAnswerProps {
  id: string
}

interface ILawPageTemplateProps {
  pageContext: {
    chapter: IChapter
    chapterIndex: number
    nextChapterPath: string | null
    previousChapterPath: string | null
    isBasicLaw: boolean
    canonicalUrl: string | null
    questionsAndAnswers: IQuestionAndAnswerData[]
  }
}

const LawPageTemplate: FC<ILawPageTemplateProps> = ({ pageContext }) => {
  const { t } = useTranslation('laws')

  const [
    isShowDeletedTextTogglerVisible,
    setIsShowDeletedTextTogglerVisible,
  ] = useState(false)
  const lawContentRef = useRef<HTMLInputElement>(null)

  const scrollToHash = () => {
    const sectionId = window.location.hash.substr(1)

    scrollToSectionId(sectionId)
  }

  useEffect(() => {
    const anyDeletedTag = lawContentRef.current?.querySelector(
      'deleted, deletedexplanation'
    )
    setIsShowDeletedTextTogglerVisible(anyDeletedTag != null)

    scrollToHash()
  }, [])

  const [isExplanationShown, setIsExplanationShown] = useState(false)
  const [explanationBoxPosition, setExplanationBoxPosition] = useState({
    x: 0,
    y: 0,
  })
  const [explanationContent, setExplanationContent] = useState('')

  const {
    chapter,
    chapterIndex,
    nextChapterPath,
    previousChapterPath,
    isBasicLaw,
    questionsAndAnswers,
  } = pageContext

  const setCurrentAnchor = sideMenuStore((state) => state.setCurrentAnchor)

  const lazyAnchorUpdating = () => {
    replaceAnchorInUrlBySections()
    setCurrentAnchor(window.location.href)
  }

  useEffect(() => {
    window.addEventListener('scroll', lazyAnchorUpdating)

    const eventRemover = toggleExplanationOnHover(
      setIsExplanationShown,
      setExplanationBoxPosition,
      setExplanationContent
    )

    return () => {
      eventRemover()
      window.removeEventListener('scroll', lazyAnchorUpdating)
    }
  })

  const pageTitle = () => {
    const prefix = isBasicLaw ? (
      <>
        {t('Law')} {chapterIndex}
        <br />
      </>
    ) : (
      ''
    )

    return (
      <span>
        <span className="hidden md:inline">
          {prefix} {chapter.desktopHeader ?? chapter.title}
        </span>
        <span className="md:hidden">
          {prefix} {chapter.mobileHeader ?? chapter.title}
        </span>
      </span>
    )
  }

  const getPageTitle = () => {
    if (!isBasicLaw) {
      return <PageTitle>{pageTitle()}</PageTitle>
    }

    return (
      <StickyTitle stickedMobileContent={`${t('Law')} ${chapterIndex}`}>
        {pageTitle()}
      </StickyTitle>
    )
  }

  const marginAfterSections = <div className="h-24 bg-white" />

  const isLastSectionWithParallax = () => {
    const lastSection = chapter.sections[chapter.sections.length - 1]

    return lastSection.transitionBlock && lastSection.transitionBlock[0]
  }

  const getLawsNavigatorProps = () =>
    pageContext.isBasicLaw
      ? {
          chapterIndex,
          nextChapterPath,
          previousChapterPath,
        }
      : null

  const description = chapter.seo?.description

  return (
    <>
      <Helmet>
        <title>{chapter.seo?.title || chapter.title}</title>
        {!!description && <meta name="description" content={description} />}
        {pageContext.canonicalUrl && (
          <link rel="canonical" href={pageContext.canonicalUrl} />
        )}
      </Helmet>
      <Layout lawsNavigatorProps={getLawsNavigatorProps()}>
        <ContentPageContainer
          id="laws-container"
          className="min-h-screen"
          isWide
        >
          <Section isNoMargin>
            <>
              <div className="container">{getPageTitle()}</div>
              <div ref={lawContentRef}>
                {chapter.sections.map((section, index) => (
                  <LawSection
                    section={section}
                    key={section.slug}
                    upperIndex={chapterIndex}
                    isBasicLaw={isBasicLaw}
                    isLast={index + 1 === chapter.sections.length}
                  />
                ))}
              </div>
              {!!questionsAndAnswers?.length && (
                <>
                  <Highlight
                    contentBlock={{
                      id: 'FAQs',
                      apiKey: apiKeys.highlight,
                      highlight: t('FAQs'),
                      extraTopMargin: true,
                      isBiggerOnMobile: true,
                    }}
                  />
                  {questionsAndAnswers.map(({ id, question, answer }) => (
                    <QuestionAndAnswer
                      key={id}
                      question={question}
                      answer={answer}
                    />
                  ))}
                </>
              )}
            </>
          </Section>
          {isLastSectionWithParallax() ? <></> : marginAfterSections}
          <ShowDeletedTextToggler visible={isShowDeletedTextTogglerVisible} />
          <LawChangeExplanation
            explanation={explanationContent}
            isShown={isExplanationShown}
            setIsShown={setIsExplanationShown}
            coordinates={explanationBoxPosition}
          />
        </ContentPageContainer>
      </Layout>
    </>
  )
}

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`

export default LawPageTemplate
